<template>
    <div class="wrapper" ref="wrapper" :style="'width:' + cssWidth + ';height:' + cssHeight"> <!-- :style="'width:' + cssWidth + ';'"> -->
        <!-- <div class="imageWrapper"> -->
            <v-row no-gutters class="imageRow">
                <v-col cols="8" class="imageCol">
                    <v-img :src="findImage('C')" v-if="findImage('C').length > 0" class="coat" aspect-ration="1"></v-img>
                    <v-img :src="findImage('S')" v-if="findImage('S').length > 0 && isGuayabera" class="coat" aspect-ration="1"></v-img>
                    <v-img :src="findImage('P')" :class="'pant' + (findImage('C').length > 0 || isGuayabera ? ' rotate90' : ' no-coat')" aspect-ration="1"></v-img>
                </v-col>

                <v-col cols="4" class="imageCol">
                    <v-img :src="findImage('S')" :style="'height:' + accesoriesImgHeight + '%'" contain v-if="findImage('S').length > 0 && !isGuayabera"></v-img>
                    <v-img :src="findImage('V')" :style="'height:' + accesoriesImgHeight + '%'" v-if="findImage('V').length > 0"></v-img>
                    <v-img :src="findImage('SH')" :style="'height:' + accesoriesImgHeight + '%'" v-if="findImage('SH').length > 0"></v-img>
                    <v-img :src="findImage('N')" :style="'height:' + accesoriesImgHeight + '%'" v-if="findImage('N').length > 0"></v-img>
                    <v-img :src="findImage('CB')" :style="'height:' + accesoriesImgHeight + '%'" v-if="findImage('CB').length > 0"></v-img>
                    <v-img :src="findImage('H')" :style="'height:' + accesoriesImgHeight + '%'" v-if="findImage('H').length > 0"></v-img>
                    <v-img :src="findImage('SO')" :style="'height:' + accesoriesImgHeight + '%'" v-if="findImage('SO').length > 0"></v-img>
                    <v-img :src="findImage('J')" :style="'height:' + accesoriesImgHeight + '%'" v-if="findImage('J').length > 0"></v-img>
                </v-col>
            </v-row>
        <!-- </div> -->
        <!-- <v-row no-gutters>
            <v-col class="d-flex flex-column" cols="8">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-sheet class="d-flex" color="grey lighten-3">
                            <img :src="findImage('C')" v-if="findImage('C').length > 0" class="coat" style="object-fit: cover;" />
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" :class="findImage('C').length > 0 ? ' rotate90' : ' no-coat'">
                        <v-sheet class="d-flex" color="grey lighten-3">
                            <img :src="findImage('P')" v-if="findImage('P').length > 0" :class="'pant'" style="object-fit: cover;" />
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-col>

            <v-col class="d-flex flex-column" cols="4">
                <v-row class="ma-n3">
                    <v-col cols="12" style="height:20%" v-if="findImage('S').length > 0">
                            <v-img :src="findImage('S')" contain></v-img>
                    </v-col>

                    <v-col cols="12" style="height:20%" v-if="findImage('V').length > 0">
                        <v-img :src="findImage('V')"></v-img>
                    </v-col>

                    <v-col cols="12" style="height:20%" v-if="findImage('SH').length > 0">
                        <v-img :src="findImage('SH')"></v-img>
                    </v-col>

                    <v-col cols="12" style="height:20%" v-if="findImage('N').length > 0">
                        <v-img :src="findImage('N')"></v-img>
                    </v-col>

                    <v-col cols="12" style="height:20%" v-if="findImage('H').length > 0">
                        <v-img :src="findImage('H')"></v-img>
                    </v-col>

                    <v-col cols="12" style="height:20%" v-if="findImage('SO').length > 0">
                        <v-img :src="findImage('SO')"></v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>
import { _st } from '@/softech';

export default {
    props: {
        outfit:     { type: Object, default: function() { return {}; } },
        width:      { type: String, default: '100%' },
        height:     { type: String, default: '100%' }
    },
    data() {
        return {
            localWidth  : this.width,
            localHeight : this.height,
        }
    },
    computed: {
        cssWidth() {
            if( this.localWidth.indexOf('%') == -1 )
                return this.localWidth + 'px';

            return this.localWidth;
        },
        cssHeight() {
            if( this.height.indexOf('%') == -1 )
                return this.localHeight + 'px';

            return this.localHeight;
        },
        isGuayabera() {
            let item = this.outfit.items.find(i => {
                return i.categoryID == 'S';
            });

            return item.style == '228' || item.style == '128';
        },
        accesoriesImgHeight() {
            let total = 0;
            if( this.findImage('S').length > 0 && !this.isGuayabera ) total++;
            if( this.findImage('V').length > 0 ) total++;
            if( this.findImage('SH').length > 0 ) total++;
            if( this.findImage('N').length > 0 ) total++;
            if( this.findImage('CB').length > 0 ) total++;
            if( this.findImage('H').length > 0 ) total++;
            if( this.findImage('SO').length > 0 ) total++;
            if( this.findImage('J').length > 0 ) total++;

            return 100.00 / total;
        },
        defaultHeight() {
            return this.$vuetify.breakpoint.name == 'xs' ? 594 : 450;
        }
    },
    mounted() {},
    methods: {
        findImage( categoryID ) {
            let item = this.outfit.items.find(i => {
                return i.categoryID == categoryID;
            });

            if( _st.isNUE( item?.imgSrc ) )
                return '';

            return item.imgSrc;
        },
    },
}
</script>

<style scoped>
.wrapper,
.wrapper .imageRow,
.wrapper .imageCol {
    background-color:#E7E7E7;
    aspect-ratio: 1 / 1.5;
}
.coat {
    height:55%;
}
.pant {
    height: 45%;
}
.pant.no-coat {
    height: 55%;
}
.rotate90 {
    transform-origin: top left;
    /* IE 10+, Firefox, etc. */
    -webkit-transform-origin: top left;
    /* Chrome */
    -ms-transform-origin: top left;
    /* IE 9 */
    transform: rotate(90deg) translateY(-100%);
    -webkit-transform: rotate(90deg) translateY(-100%);
    -ms-transform: rotate(90deg) translateY(-100%);
}
</style>