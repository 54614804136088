<template>
    <v-dialog :value="value" fullscreen color="white"> <!-- :opacity="canShowDescription ? 1 : 0.46" -->
        <v-card style="height:100%;" color="white">
            <v-row no-gutters>
                <v-col cols="12" md="6" lg="3">
                    <div style="height:calc(100vh - 220px);position:relative;">
                        <package-image :outfit="selectedCombination" v-if="imgSelected == -1"></package-image>
                        <v-img :src="localProduct.gallery[imgSelected]" width="100%" height="100%" v-else></v-img>
                        <v-btn fab depressed small style="position:absolute;right: 10px; top: calc((100vh - 220px) / 2.2);background-color:#00000033;"
                            @click="changePic(1)">
                            <v-icon color="white">mdi-arrow-right</v-icon>
                        </v-btn>
                        <v-btn fab depressed small style="position:absolute;left: 10px; top: calc((100vh - 220px) / 2.2);background-color:#00000033;" 
                            @click="changePic(-1)">
                            <v-icon color="white">mdi-arrow-left</v-icon>
                        </v-btn>
                    </div>
                        
                    <div class="picker" style="background-color: #fff;" v-if="localProduct.image != ''">
                        <v-card class="item mr-2 mt-2" rounded flat color="#e7e7e7" @click="imgSelected = -1">
                            <package-image :outfit="selectedCombination" width="125" height="200px"></package-image>

                            <v-overlay absolute :value="imgSelected == -1">
                            </v-overlay>
                        </v-card>

                        <!-- <v-card class="item mr-2 mt-2" rounded flat @click="imgSelected = 1">
                            <v-img :src="localProduct.image" width="125" height="200px"></v-img>

                            <v-overlay absolute :value="imgSelected == 1">
                            </v-overlay>
                        </v-card> -->

                        <template v-for="(img, ix) in localProduct.gallery">
                            <v-card class="item mr-2 mt-2" rounded flat @click="imgSelected = ix" :key="ix">
                                <v-img :src="img" width="125" height="200px"></v-img>

                                <v-overlay absolute :value="imgSelected == ix">
                                </v-overlay>
                            </v-card>
                        </template>
                    </div>
                </v-col>
                <v-col cols="12" md="6" lg="9" class="pt-2 text-wrapper white">
                    <v-card-title class="display-1 pt-0" style="word-break: break-word">
                        {{ localProduct.name }}
                        
                        <v-spacer></v-spacer>
                        
                        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-subtitle>
                        {{ formatMoney( getPackagePrice( localProduct.price ) ) }} 
                        <span style="text-decoration:line-through;" class="caption error--text" v-show="promoCode != null">{{ formatMoney( localProduct.price ) }} </span>
                    </v-card-subtitle>

                    <v-card-text class="text-wrapper">

                        <div class="title mt-3 mb-2 font-weight-regular" v-show="this.localProduct.combinations != undefined && this.localProduct.combinations != null && localProduct.combinations.length > 0">Package Description</div>
                        <div v-html="localProduct.description"></div>

                        <!-- <div class="filler"></div> -->
                        
                        <!-- <div class="title mt-3 mb-2 font-weight-regular" v-show="this.localProduct.combinations != undefined && this.localProduct.combinations != null && localProduct.combinations.length > 0" v-if="!isMobile">Available Combinations</div>
                        <div v-show="this.localProduct.combinations != undefined && this.localProduct.combinations != null && localProduct.combinations.length > 0" class="picker" v-if="!isMobile">
                            <template v-for="(comb, ix) in localProduct.combinations">
                                <v-card :key="ix" class="item mx-2" rounded flat @click="selectCombination(comb)" v-if="comb.active">
                                    <package-image :outfit="comb" width="125" height="200px"></package-image>

                                    <v-overlay absolute :value="comb.selected">
                                        <v-chip color="green">SELECTED</v-chip>
                                    </v-overlay>
                                </v-card>
                            </template>
                        </div> -->


                        <h2 class="title mt-3 mb-2 font-weight-regular" style="text-transform: none;"> Change accesories at no extra charge <span class="body-1 grey--text">- shoes not included with package</span></h2>

                        <v-expansion-panels accordion>
                            <v-expansion-panel>
                                <v-expansion-panel-header>Pant</v-expansion-panel-header>
                                <v-expansion-panel-content style="overflow:auto;">
                                    <v-row>
                                        <v-col v-for="(item, ix) in getAccesories('P')" :key="ix" class="d-flex child-flex mx-0" cols="6" md="2">
                                            <v-card class="item mx-1" width="100" :key="ix" flat :ripple="false" @click="selectItem(item)">
                                                <v-img :src="item.imgSrc" :lazy-src="item.imgSrc" aspect-ratio="1" class="grey lighten-2 rounded"></v-img>

                                                <v-overlay absolute :value="item.selected">
                                                    <v-chip small color="primary">SELECTED</v-chip>
                                                </v-overlay>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-btn depressed block color="error" class="mt-4" @click="removeItem('P')">Remove</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Shirt</v-expansion-panel-header>
                                <v-expansion-panel-content style="max-height:300px;overflow:auto;">
                                    <v-row>
                                        <v-col v-for="(item, ix) in getAccesories('S')" :key="ix" class="d-flex child-flex mx-0" cols="6" md="2">
                                            <v-card class="item mx-1" width="100" :key="ix" flat :ripple="false" @click="selectItem(item)">
                                                <v-img :src="item.imgSrc" :lazy-src="item.imgSrc" aspect-ratio="1" class="grey lighten-2 rounded"></v-img>

                                                <v-overlay absolute :value="item.selected">
                                                    <v-chip small color="primary">SELECTED</v-chip>
                                                </v-overlay>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Neckwear</v-expansion-panel-header>
                                <v-expansion-panel-content style="max-height:300px;overflow:auto;">
                                    <v-row>
                                        <v-col v-for="(item, ix) in getAccesories('N')" :key="ix" class="d-flex child-flex mx-0" cols="6" md="2">
                                            <v-card class="item mx-1" width="100" :key="ix" flat :ripple="false" @click="selectItem(item)">
                                                <v-img :src="item.imgSrc" :lazy-src="item.imgSrc" aspect-ratio="1" class="grey lighten-2 rounded"></v-img>

                                                <v-overlay absolute :value="item.selected">
                                                    <v-chip small color="primary">SELECTED</v-chip>
                                                </v-overlay>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-btn depressed block color="error" class="mt-4" @click="removeItem('N')">Remove</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Vest / Cummerbund</v-expansion-panel-header>
                                <v-expansion-panel-content style="max-height:300px;overflow:auto;">
                                    <v-row>
                                        <v-col v-for="(item, ix) in getAccesories('V')" :key="ix" class="d-flex child-flex mx-0" cols="6" md="2">
                                            <v-card class="item mx-1" width="100" :key="ix" flat :ripple="false" @click="selectItem(item)">
                                                <v-img :src="item.imgSrc" :lazy-src="item.imgSrc" aspect-ratio="1" class="grey lighten-2 rounded"></v-img>

                                                <v-overlay absolute :value="item.selected">
                                                    <v-chip small color="primary">SELECTED</v-chip>
                                                </v-overlay>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-btn depressed block color="error" class="mt-4" @click="removeItem('V')">Remove</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Jewelry</v-expansion-panel-header>
                                <v-expansion-panel-content style="max-height:300px;overflow:auto;">
                                    <v-row>
                                        <v-col v-for="(item, ix) in getAccesories('J')" :key="ix" class="d-flex child-flex mx-0" cols="6" md="2">
                                            <v-card class="item mx-1" width="100" :key="ix" flat :ripple="false" @click="selectItem(item)">
                                                <v-img :src="item.imgSrc" :lazy-src="item.imgSrc" aspect-ratio="1" class="grey lighten-2 rounded"></v-img>

                                                <v-overlay absolute :value="item.selected">
                                                    <v-chip small color="primary">SELECTED</v-chip>
                                                </v-overlay>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-btn depressed block color="error" class="mt-4" @click="removeItem('J')">Remove</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            
                            <v-expansion-panel>
                                <v-expansion-panel-header>Shoes - add $25</v-expansion-panel-header>
                                <v-expansion-panel-content style="max-height:300px;overflow:auto;">
                                    <v-row>
                                        <v-col v-for="(item, ix) in getAccesories('SH')" :key="ix" class="d-flex child-flex mx-0" cols="6" md="2">
                                            <v-card class="item mx-1" width="100" :key="ix" flat :ripple="false" @click="selectItem(item)">
                                                <v-img :src="item.imgSrc" :lazy-src="item.imgSrc" aspect-ratio="1" class="grey lighten-2 rounded"></v-img>

                                                <v-overlay absolute :value="item.selected">
                                                    <v-chip large color="primary" class="caption text-center">SELECTED <br> add $25</v-chip>
                                                </v-overlay>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-btn depressed block color="error" class="mt-4" @click="removeItem('SH')">Remove</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                    </v-card-text>

                    <v-card-actions class="">
                        <v-btn block depressed x-large color="grey darken-4 white--text" bottom 
                            @click="selectPackage('rent')" style="flex-grow:0;text-transform:none;">
                            Rent {{ formatMoney( price ) }}
                        </v-btn>
                    </v-card-actions>
                </v-col>

            </v-row>
        </v-card>

        <v-dialog v-model="showRentBuyDialog" width="500" class="mx-2">
            <v-card color="white">
                <v-card-title :class="($vuetify.breakpoint.smAndDown ? 'title font-weight-regular' : 'headline') + ' font-weight-regular'">
                    What do you want to do?

                    <v-spacer></v-spacer>

                    <v-icon @click="showRentBuyDialog = false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="my-0 py-0">
                            <v-alert color="warning" class="mb-0 white--text">
                                Currently we are not providing the shoes for any of our packages. We're sorry of any inconvenience this may cause.
                            </v-alert>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat color="grey lighten-2" @click="selectPackage('rent')">
                                <v-card-text class="text-center">
                                    <div class="title">Rent</div>
                                    <div>for {{ formatMoney( getPackagePrice( localProduct.price ) ) }}</div>
                                </v-card-text>        
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import Vue from 'vue';
import PackageImage from '@/components/PackageImage.vue';
import { _st } from '@/softech';
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/inc/api';

export default {
    props: {
        value       : { type: Boolean, default: false },
        product     : { type: Object, default: () => { return {}; } },
        target      : { type: HTMLDivElement, default: null },
    },
    data() {
        return {
            animationClasses: '',
            imageWidth: 0,
            canShowDescription: false,
            showRentBuyDialog: false,
            imgSelected: -1,

            localProduct: this.product == null ? {} : JSON.parse( JSON.stringify( this.product ) ),
            localAccesories: [],
            accesories: [],

            continueBtnWidth: 0,

            isMobile: false,
        }
    },
    mounted() {
        this.getContinueBtnWidth();
        this.loadAccesories();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
    watch: {
        value(v) {
            if( v ) {
                // setTimeout( () => {
                //     let image = document.getElementById('productImage');
                //     this.imageWidth = image.offsetWidth;

                //     let root = document.documentElement;
                //     root.style.setProperty('--left', ( this.left - ( window.innerWidth / 2 ) ) + "px");
                //     root.style.setProperty('--top', this.top + "px");
                //     root.style.setProperty('--start', '-' + ( window.innerWidth / 2 - ( image.offsetWidth / 2) ) + "px");

                //     this.animationClasses = 'anim';

                //     setTimeout( () => {
                //         this.canShowDescription = true;
                //     }, 900)
                // }, 100);
            }
            else 
                this.animationClasses = '';
        },
        product(v) {
            this.localProduct = JSON.parse( JSON.stringify( v ) );
            this.imgSelected = -1;
            if( _st.isNUE( this.localProduct.combinations ) )
                return;

            this.localProduct.combinations.forEach((c) => {
                if( c.default )
                    Vue.set(c, 'selected', true);
                else
                    Vue.set(c, 'selected', false);
            });

            this.localAccesories.forEach(i => i.selected = false);
            this.selectedCombination.items.forEach(i => {
                let ix = this.localAccesories.findIndex(a => a.id == i.id);
                if( ix != -1 )
                    this.localAccesories[ix].selected = true;
            });
        }
    },
    computed: {
        ...mapGetters({
            promoCode : 'promoCode'
        }),
        selectedCombination() {
            // get combination selected
            if( _st.isNUE( this.localProduct.combinations ) )
                return null;

            let comb = this.localProduct.combinations.find(c => {
                return c.selected;
            });

            if( comb != undefined && comb != null ) {
                return comb;
            }

            return null;
        },
        imgSrc() {
            if( _st.isNUE( this.localProduct.combinations ) )
                return '';

            // get combination selected
            let comb = this.localProduct.combinations.find(c => {
                return c.selected;
            });

            if( comb != undefined && comb != null ) {
                return require('../assets/packages/' + comb.image );
            }

            if( this.localProduct != null && this.localProduct.image != undefined && this.localProduct.image != null && this.localProduct.image != '' ) 
                return require('../assets/packages/' + this.localProduct.image);
            
            return '';
        },
        left() {
            return this.target.getBoundingClientRect().left;
        },
        top() {
            return this.target.getBoundingClientRect().top;
        },
        width() {
            return this.target.width;
        },
        descriptionStyle() {
            return '';
            // console.log( window.innerWidth / 2 );
            // console.log( this.imageWidth );
            // let style = 'position:absolute;left:-' + ( window.innerWidth / 2 - this.imageWidth - this.imageWidth / 2 ) + 
            //     'px;width:' + (window.innerWidth - this.imageWidth) + 'px;height:100%;';

            // console.log( style );
            // return style;
        },
        price() {
            if( _st.isNUE( this.localProduct.combinations ) )
                return 0;
                
            let combination = this.localProduct.combinations.find(c => {
                return c.selected;
            });

            if( _st.isNUE( combination ) )
                return this.getPackagePrice( this.localProduct.price );
            
            // check if combination containes shoes
            let ix = combination.items.findIndex(i => i.categoryID == 'SH' );
            if( ix == -1 )
                return this.getPackagePrice( this.localProduct.price );

            return parseFloat( this.getPackagePrice( this.localProduct.price ) ) + 25;
        }
    },
    methods: {
        ...mapActions({
            clearOutfit     : 'clearOutfit',
            setOutfit       : 'setOutfit',
            setAction       : 'setAction',
            setProduct      : 'setProduct',
        }),
        async loadAccesories() {
            try {
                let api = new API();
                let res = await api.get('/accesories');

                if( res.data.status !== true ) {
                this.mxShowMessage( res.data.error == true ? 'Error' : 'Warning', res.data.message, res.data.error == true ? 'error' : 'warning' );
                return;
                }

                this.accesories = res.data.data;
                this.localAccesories = res.data.data;
            }
            catch(error) {
                this.mxShowMessage( 'Error', error, 'error' );
            }
        },
        selectPackage( action ) {
            if( _st.isNUE( this.localProduct.combinations ) )
                return;

            // find the selected combination
            let combination = this.localProduct.combinations.find(c => {
                return c.selected;
            });

            if( _st.isNUE( combination ) ) {
                this.mxShowMessage( 'Error', 'You must select a combination before continueing', 'warning' );
                return;
            }

            this.clearOutfit();
            this.setOutfit( combination );
            this.setProduct( this.localProduct );
            this.setAction( action );

            this.close();
            this.$emit('selected');
        },
        close() {
            this.$emit( 'input', false );
            this.animationClasses = '';
            this.canShowDescription = false;
        },
        selectCombination(comb) {
            if( _st.isNUE( this.localProduct.combinations ) )
                return;

            this.localProduct.combinations.forEach(c => {
                c.selected = false;
            });

            comb.selected = true;
        },
        getAccesories( categoryId ) {
            if( _st.isNUE( this.localAccesories ) )
                return [];

            return this.localAccesories.filter(a => {
                if( categoryId == 'V')
                    return a.categoryID == categoryId || a.categoryID == 'CB';

                return a.categoryID == categoryId;
            });
        },
        selectItem( item ) {
            // find the selected combination

            // check if the selected item is already selected
            let selected = this.localAccesories.find(a => {
                if( a.categoryID == 'V' || a.categoryID == 'CB' )
                    return ( a.categoryID == 'V' || a.categoryID == 'CB' ) && a.selected == true;

                return a.categoryID == item.categoryID && a.selected == true;
            });

            if( selected !== undefined && selected == item ) {
                item.selected = false;
            }
            else {
                if( selected !== undefined )
                    selected.selected = false;
                    
                item.selected = true;
            }

            // remove current item of the same category from the combination
            let ix = this.selectedCombination.items.findIndex(i => {
                if( i.categoryID == 'V' || i.categoryID == 'CB' )
                    return i.categoryID == 'V' || i.categoryID == 'CB';

                return i.categoryID == item.categoryID;
            });
            if( ix != -1 )
                this.selectedCombination.items.splice( ix, 1 );

            // add the new item to the combination
            if( item.selected )
                this.selectedCombination.items.push( item );
            else {
                let i = this.product.combinations[0].items.find(i => i.categoryID == item.categoryID );
                if( !_st.isNUE( i ) )
                    this.selectedCombination.items.push( i );
            }

            if( !item.selected ) {
                this.selectedCombination.items.forEach(i => {
                    let ix = this.localAccesories.findIndex(a => a.id == i.id);
                    if( ix != -1 )
                        this.localAccesories[ix].selected = true;
                });
            }

            this.showAccesoryDialog = false;
        },
        removeItem( categoryId = '' ) {
            let selected = this.localAccesories.find(a => {
                return a.categoryID == categoryId && a.selected == true;
            });
            
            if( selected !== undefined && selected !== null ) {
                selected.selected = false;
            }

            let ix = this.selectedCombination.items.findIndex(i => i.categoryID == categoryId);
            if( ix != -1 ) 
                this.selectedCombination.items.splice( ix, 1 );

            this.showAccesoryDialog = false;
        },
        getPackagePrice( regularPrice ) {
            if( this.promoCode == null )
                return regularPrice;

            return parseFloat( regularPrice ) - parseFloat( this.promoCode.amount );
        },
        getContinueBtnWidth() {
            if( this.$refs.sidebar == undefined )
                return 'auto';
                
            this.continueBtnWidth = this.$refs.sidebar.offsetWidth - 31;
        },
        changePic(i) {
            let ix = this.imgSelected + i;
            if( ix >= this.localProduct.gallery.length )
                ix = -1;
            else if( ix < -1 )
                ix = this.localProduct.gallery.length - 1;

            this.imgSelected = ix;
        },
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
    },
    beforeDestroy () {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize, { passive: true })
        }
    },
    components: {
        PackageImage
    }
}
</script>

<style scoped>
/* :root {
  --left: 0px;
  --top: 0px;
  --start: 0px;
} */
.picker {
    display: flex;
    overflow-x: auto;
    padding: 5px 0;
}
.picker .item {
    flex: 0 0 100px;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
}
.text-wrapper .filler {
    flex-grow: 1;
}

/* .anim {
    -webkit-animation-duration:0.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;
    -webkit-animation: toTheLeftAnimation 0.5s both ease-in;
}
.fullImg {
    transform: scale(100%);
}
.toTheLeft {
    -webkit-animation-name: toTheLeftAnimation;
}
@-webkit-keyframes toTheLeftAnimation {
  from { transform: translatex( var( --left ) ) translateY( 0 ) }
  to { transform: translatex(var( --start ) ) translateY( 0 ) }
} */
</style>

<style>
#carousel .v-carousel__controls .v-carousel__controls__item.v-btn.v-btn--icon {
    color: #455A64;
}
</style>