<template>
  <div>
    <!-- Package selection view -->
    <v-card flat class="fill-height box">
      <v-app-bar flat class="row header mx-0">
        <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
        <v-divider class="mx-3" inset vertical></v-divider>
        <span class="headline">Rental Shop</span>

      </v-app-bar>
      <v-card-text class="row content mx-0">
        <div>
          <v-card-title class="headline pl-0">Pick your outfit</v-card-title>
          <v-card-subtitle class="pl-0">Select your outfit and then click finish when you're ready</v-card-subtitle>
        </div>

        <v-row align-content="center" justify="center" style="width:100%;">

          <v-col cols="12" class="display-1 mt-10 text-center font-weight-regular" v-show="loadingPackages">
            Loading packages...
          </v-col>

          <v-col cols="12" class="my-5 font-weight-regular" v-show="!loadingPackages">
            Filter: 
            <v-chip class="mx-2 mb-1" :outlined="filter != 'all'" @click="filter = 'all'">All</v-chip>
            <v-chip class="mx-2 mb-1" :outlined="filter != 'black'" @click="filter = 'black'"><v-avatar left class="black"></v-avatar> Black</v-chip>
            <v-chip class="mx-2 mb-1" :outlined="filter != 'blue'" @click="filter = 'blue'"><v-avatar left class="blue darken-2"></v-avatar> Blue</v-chip>
            <v-chip class="mx-2 mb-1" :outlined="filter != 'grey'" @click="filter = 'grey'"><v-avatar left class="grey"></v-avatar> Grey</v-chip>
            <v-chip class="mx-2 mb-1" :outlined="filter != 'other'" @click="filter = 'other'">Other colors</v-chip>
          </v-col>

          <!-- Package selection -->
          <template v-for="(p, ix) in filteredPackages">
            <v-col cols="12" md="3" lg="2" :key="ix" v-if="p.combinations.length > 0">
              <v-card flat @click="open(p, $event)">
                <!-- <v-img :src="require('../assets/packages/' + p.image)" :ref="'p' + ix" @click="open(p, $event)"></v-img> -->
                <v-sheet>
                  <v-img :src="p.image" v-if="p.image != null && p.image.length > 0"></v-img>
                  <package-image :outfit="p.combinations[0]" v-else></package-image>
                </v-sheet>
                <v-card-title class="px-0 pt-0 font-weight-regular">{{ p.name }}</v-card-title>
                <v-card-subtitle class="px-0">
                  {{ formatMoney( getPackagePrice( p.price ) ) }} 
                  <span style="text-decoration:line-through;" class="caption error--text" v-show="promoCode != null">{{ formatMoney( p.price ) }} </span>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </template>

        </v-row>

        <v-btn absolute bottom left depressed x-large color="error" @click="restart" :icon="$vuetify.breakpoint.smAndDown">
          <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-restart</v-icon> 
          <span v-if="!$vuetify.breakpoint.smAndDown">Restart</span>
        </v-btn>

      </v-card-text>

    </v-card>

    <!-- <v-btn fixed bottom right depressed x-large color="primary" @click="nextStep" :loading="loading">
      <v-icon left>mdi-arrow-right</v-icon> Next
    </v-btn> -->

    <!-- Package description view -->
    <package-view v-model="showPackage" :product="selectedPackage" :accesories="accesories" :target="selectedPackageTarget"
      @selected="nextStep"></package-view>

    <v-overlay :value="checkingAvailability">
      <v-card width="300">
        <v-card-text>
          Checking outfit availability
          <v-progress-linear indeterminate class="mt-3"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { API } from '@/inc/api';
import PackageView from '@/components/PackageView.vue';
import PackageImage from '@/components/PackageImage.vue';
import { _st } from '@/softech';

export default {
  data: () => ({
    packages              : [],
    accesories            : [],
    selectedPackage       : null,
    showPackage           : false,
    selectedPackageTarget : null,

    loadingPackages       : false,
    loading               : false,
    checkingAvailability  : false,

    filter                : 'all'
  }),
  computed: {
    ...mapGetters({
      useDate     : 'useDate',
      heightFeet  : 'heightFeet',
      heightInches: 'heightInches',
      weight      : 'weight',
      fit         : 'fit',
      customer    : 'customer',
      product     : 'product',
      outfit      : 'outfit',
      event       : 'event',
      eventRole   : 'eventRole',
      action      : 'action',
      promoCode   : 'promoCode',
    }),
    filteredPackages() {
      if( this.filter == 'all' )
        return this.packages;

      let result = [];
      switch( this.filter ) {
        case 'other':
          result = this.packages.filter(p => p.color != 'black' && p.color != 'blue' && p.color != 'grey');
          break;
        default: 
          result = this.packages.filter(p => p.color == this.filter);
          break;
      }

      return result;
    }
  },
  mounted() {
    if( _st.isNUE( this.useDate ) || _st.isNUE( this.heightFeet ) || _st.isNUE( this.heightInches) || _st.isNUE( this.weight ) )
      this.$router.push('/');

    this.loadPackages();
  },
  methods: {
    ...mapActions({
      clearCustomer : 'clearCustomer',
      clearOutfit   : 'clearOutfit',
      setEventRole  : 'setEventRole',
      clearEvent    : 'clearEvent',
      setUseDate    : 'setUseDate'
    }),
    open( p, e ) {
      this.selectedPackage = p;
      this.selectedPackageTarget = e.target;
      this.showPackage = true;
    },
    async loadPackages() {
      try {
        this.loadingPackages = true;

        let api = new API();
        let res = await api.get('/packages');
        this.loadingPackages = false;

        if( res.data.status !== true ) {
          this.mxShowMessage( res.data.error == true ? 'Error' : 'Warning', res.data.message, res.data.error == true ? 'error' : 'warning' );
          return;
        }

        this.packages = res.data.data;
      }
      catch(error) {
        this.loadingPackages = false;
        this.mxShowMessage( 'Error', error, 'error' );
      }
    },
    async nextStep() {
      if( _st.isNUE( this.outfit ) || this.outfit.length == 0 ) {
        this.mxShowMessage( 'Error', 'You must select an outfit before finishing the reservation', 'error' );
        return;
      }
      if( _st.isNUE( this.event ) && this.event.id > 0 &&
        ( _st.isNUE( this.eventRole ) || this.eventRole == 0 ) ) {
        this.mxShowMessage( 'Error', 'You must select your role in the event selected', 'error' );
        return;
      }

      // checking availability
      try {
        let api = new API();

        this.checkingAvailability = true;
        let res = await api.post('/availability', {
          useDate             : this.useDate,
          packageId           : this.product.id,
          combinationId       : this.outfit.id,
          heightFeet          : this.heightFeet,
          heightInches        : this.heightInches,
          weight              : this.weight,
          fit                 : this.fit
        });
        this.checkingAvailability = false;

        if( res.data.status !== true ) {
          this.mxShowMessage( res.data.error == true ? 'Error' : 'Warning', res.data.message, res.data.error == true ? 'error' : 'warning' );
          return;
        }

        this.$router.push('/customer-info');
      }
      catch(error) {
        this.checkingAvailability = false;
        this.mxShowMessage( 'Error', error, 'error' );
      }
    },
    getPackagePrice( regularPrice ) {
      if( this.promoCode == null )
        return regularPrice;

      return parseFloat( regularPrice ) - parseFloat( this.promoCode.amount );
    }
  },
  beforeRouteLeave(to, from, next) {
    if( this.showPackage === true ) {
      this.showPackage = false;
      next(from);
    }

    next();
  },
  components: {
    PackageView, PackageImage 
  }
}
</script>

<style scoped>
.v-card__title {
  word-break: normal !important;
}
</style>